.sports-menu {
  #toggle {
    appearance: none;
    display: inline-flex;
  }

  .wi-Menu {
    position: absolute;
    bottom: 100px;
    right: 72px;
    width: 90%;
    min-height: 500px;
    max-height: 800px;
    max-width: 300px;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0.5;
    backdrop-filter: blur(10px);
    padding: 10px 15px;
    border-radius: 5px;
    overflow: auto;
    transition: transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275),
      opacity 0.2s;
    opacity: 0;
    transform: scale(0);
    transform-origin: 100% 100%;
    &[aria-hidden="false"] {
      transform: none;
      opacity: 1;
      transition: transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275),
        opacity 0.3s;
    }
  }

  .wi-Menu_Row {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 45px;
    font-size: 18px;

    max-height: 500px;
    overflow: auto;
  }

  .accordion {
    width: 100%;
    border: none !important;
    margin-top: 10px;
    .accordion__item {
      margin-bottom: 10px;
      width: 90%;
    }
    .accordion__button {
      border-radius: 20px;
      font-size: 14px;
      padding: 3px 3px 3px 10px;
    }
    .accordion__panel {
      color: #fff;
      font-size: 12px;
      padding: 5px;
      label {
        display: flex;
        align-items: center;
        padding-left: 20px;
        input {
          margin-right: 10px;
        }
      }
    }
  }
}

.fixtures {
  .sp-Menu {
    position: absolute;
    bottom: 58px;
    left: 72px;
    width: 90%;

    max-height: 600px;
    // margin: 10px 10px;
    max-width: 300px;

    overflow: auto;
    transition: transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275),
      opacity 0.2s;
    opacity: 0;
    transform: scale(0);
    transform-origin: 100% 100%;
    &[aria-hidden="false"] {
      transform: none;
      opacity: 1;
      transition: transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275),
        opacity 0.3s;
    }

    .card {
      background-color: rgba(0, 0, 0, 0.5);
      opacity: 0.75;
      backdrop-filter: blur(10px);
      color: #fff;
      padding: 10px 15px;
      border-radius: 5px;
      position: relative;
    }
    .card-active {
      background-color: rgb(189, 255, 36);
      backdrop-filter: blur(10px);
      color: #444;
      padding: 10px 15px;
      border-radius: 5px;
      position: relative;
    }
  }
}

.slick-list .slick-track {
  display: flex !important;
  align-items: center !important;
}
