@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./sports.less";

html {
  box-sizing: border-box;
}
* {
  /*all: unset;*/
  /*This prevents users being able to select text. Stops long presses in iOS bringing up copy/paste UI for example. Note below we specifically switch user-select on for inputs for the sake of Safari. Bug here: https://bugs.webkit.org/show_bug.cgi?id=82692*/
  user-select: none;
  /*This gets -webkit specific prefix as it is a non W3C property*/
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  /*Older Androids need this instead*/
  -webkit-tap-highlight-color: transparent;
  /* Most devs find border-box easier to reason about. However by inheriting we can mix box-sizing approaches.*/
  box-sizing: inherit;
}
body {
  background-image: url("../images/background.jpeg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
  position: relative;

  .search-container {
    transform: translate(-50%, -80%);
  }
  .wallpaper {
    width: 90%;
    margin-bottom: 5px;
    cursor: pointer;
  }
}

.news-section {
  .fd-Menu {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    min-height: 310px;
    max-height: 600px;
    overflow: auto;
    transition: all 0.2s ease-in-out;
    opacity: 0;
    background-color: rgba(67, 67, 134, 0.92);
    visibility: hidden;
    z-index: 3;
    &[aria-hidden="false"] {
      visibility: visible;
      opacity: 1;
    }

    select {
      min-width: max-content;
    }
  }
  .slide {
    transform: scale(0.7);
    transition: transform 300ms;
    opacity: 0.5;
  }

  .slideWrapper {
    display: flex;
    justify-content: center;
  }

  .prevArrow {
    position: absolute;
    top: 40%;
    left: 60px;
    z-index: 100;
    cursor: pointer;
    font-size: 2rem;
  }

  .nextArrow {
    position: absolute;
    top: 40%;
    right: 60px;
    z-index: 100;
    cursor: pointer;
    font-size: 2rem;
  }
  .feeds-btn {
    transform: translate(-50px, 10px);
    width: 50px;
  }
  //   .image-container .after {
  //     position: absolute;
  //     top: 0;
  //     left: 0;
  //     width: 100%;
  //     height: 100%;
  //     display: none;
  //     color: #fff;
  //   }
  .centered {
    display: flex;
    align-items: center;
    text-align: center;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    border-radius: 15px;
  }

  .image-container:hover .centered {
    display: flex;
    align-items: center;
    text-align: center;
    background: rgba(0, 0, 0, 0.6);
    border-radius: 15px;
  }
  .prevArrow {
    color: #fff;
  }
  .nextArrow {
    color: #fff;
  }
}

.tweet-section {
  .tweet-Menu {
    position: absolute;
    top: 0;
    left: 18%;
    transform: translate(-54%, 10px) !important;
    width: 25%;
    max-height: 600px;
    max-width: 70%;
    overflow: auto;
    transition: transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275),
      opacity 0.2s;
    opacity: 0;
    transform: scale(0);
    transform-origin: 100% 100%;
    &[aria-hidden="false"] {
      transform: none;
      opacity: 1;
      transition: transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275),
        opacity 0.3s;
    }
  }
  .twitter-card {
    backdrop-filter: blur(10px);
  }
}
#searchform {
  input {
    width: 900px;
  }
}
@media only screen and (max-width: 1000px) {
  #searchform {
    input {
      width: 600px;
    }
  }
}

.perso-1 {
  background-color: #fff !important;
  color: rgba(0, 0, 0, 0.8);
}

.petite {
  width: 5rem !important;
}

.perso-2 {
  display: none;
}

.lefty {
  left: 98%;
  z-index: 4;
}

.pazaz {
  left: 50% !important;
  transform: translate(-50%, 0%) !important;
}

.nani {
  display: none !important;
}

.hover-perso:hover {
  color: rgba(67, 67, 134, 1);
}

.rad img {
  border-radius: 15px;
}

.marginalos {
  margin-top: 15px;
  padding-left: 15px;
}

.broccoli {
  right: 5px !important;
}

.carrot {
  left: 5px !important;
}

.perso-3 {
  border-color: rgb(67, 67, 134);
}

input::placeholder,
textarea::placeholder {
  color: rgba(67, 67, 134, 0.55) !important;
}
